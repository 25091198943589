// Bootstrap
//@import '~bootstrap/scss/bootstrap';


// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


$main-color: #082664;
$blue-color: #0098CA;
