@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w33 {
  width: 33.33333%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w100 {
  width: 100%;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

header {
  padding: 10px 0;
}
header div.wrapper-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
header div.visible {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-style: normal;
  font-weight: 500;
}
header div.visible .item {
  padding: 5px;
  cursor: pointer;
}
header div.visible .big {
  font-size: 26px;
}
header div.visible .main {
  font-size: 22px;
}
header div.visible .small {
  font-size: 18px;
}
header div.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
header div.contacts .phone {
  text-decoration: none;
  color: #082664;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  cursor: pointer;
}
header div.contacts .email {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #082664;
  letter-spacing: 0.6px;
}
header div.lang {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}
header div.lang .item {
  display: block;
  padding: 10px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #082664;
}
header div.lang .item:after {
  content: "";
  background: #082664;
  width: 1px;
  height: 20px;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
header div.lang .item:last-child:after {
  display: none;
}
header div.description {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  max-width: 350px;
}
header a.logo {
  display: block;
  width: 200px;
}
header a.logo img {
  width: 100%;
  display: block;
}
@media screen and (max-width: 800px) {
  header div.wrapper-container {
    flex-wrap: wrap;
  }
  header div.wrapper-container .logo, header div.wrapper-container .description {
    width: 50%;
  }
  header div.wrapper-container .description {
    text-align: right;
    padding-left: 10px;
    font-size: 14px;
    white-space: nowrap;
  }
}
@media screen and (max-width: 600px) {
  header div.contacts {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  header div.wrapper-container .logo {
    width: 100%;
    margin-bottom: 10px;
  }
  header div.wrapper-container .logo img {
    max-width: 300px;
    margin: 0 auto;
  }
  header div.wrapper-container .description {
    display: none;
  }
}
.head-menu {
  background: #082664;
  color: #ffffff;
}
.head-menu ul.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.head-menu ul.menu li {
  list-style: none;
  position: relative;
}
.head-menu ul.menu li > a, .head-menu ul.menu li > span.link {
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  padding: 14px 15px;
  display: block;
}
.head-menu ul.menu li > a:hover, .head-menu ul.menu li > span.link:hover {
  /*&:after {
      content: '';
      height: 3px;
      background: #ffffff;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
  }*/
}
.head-menu ul.menu li:hover a, .head-menu ul.menu li:hover span.link {
  background: #183573;
}
.head-menu ul.menu li:hover a:after, .head-menu ul.menu li:hover span.link:after {
  content: "";
  height: 3px;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.head-menu ul.menu li:hover .submenu {
  display: block;
}
.head-menu ul.menu li .submenu {
  z-index: 10;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: #ffffff;
  -webkit-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
}
.head-menu ul.menu li .submenu ul {
  flex-direction: column;
  align-items: stretch;
  flex-shrink: 0;
}
.head-menu ul.menu li .submenu ul li {
  border-bottom: 1px solid #0098CA;
}
.head-menu ul.menu li .submenu ul li:last-child {
  border-bottom: none;
}
.head-menu ul.menu li .submenu a {
  padding: 15px 20px;
  background: #082664;
  color: #ffffff;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}
.head-menu ul.menu li .submenu a:after {
  display: none;
}
.head-menu ul.menu li .submenu a:hover {
  background: #0098CA;
  color: #ffffff;
}
.head-menu ul.menu li .submenu a:hover:after {
  display: none;
}
.head-menu .mobile {
  display: none;
}
.head-menu .mobile div.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.head-menu .mobile div.contacts .phone {
  text-decoration: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  cursor: pointer;
}
.head-menu .mobile div.contacts .email {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 0.6px;
}
@media screen and (max-width: 600px) {
  .head-menu .mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .head-menu .mobile .menu-button {
    display: block;
    padding: 10px;
  }
  .head-menu .mobile .menu-button svg {
    transform: scale(0.7);
    fill: #ffffff;
  }
  .head-menu .mobile .menu-button:active {
    background: #183573;
  }
  .head-menu ul.menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 10;
    width: 300px;
    -webkit-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  }
  .head-menu ul.menu > li a, .head-menu ul.menu > li span.link {
    color: #082664;
  }
  .head-menu ul.menu > li:hover > a, .head-menu ul.menu > li:hover > span.link {
    color: #ffffff;
    background: #183573;
  }
  .head-menu ul.menu > li:hover > a:after, .head-menu ul.menu > li:hover > span.link:after {
    display: none;
  }
  .head-menu ul.menu > li:hover .submenu {
    display: block;
  }
  .head-menu ul.menu > li .submenu {
    background: #ffffff;
    position: static;
    width: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .head-menu ul.menu > li .submenu ul li a {
    padding-left: 30px;
  }
}

footer {
  background: #082664;
  color: #ffffff;
}
footer .wrapper-container {
  padding: 20px;
}
footer .logo {
  display: block;
  text-decoration: none;
  width: 100%;
  max-width: 250px;
}
footer .logo img {
  width: 100%;
  display: block;
}
footer .address {
  padding: 0 10px;
  color: #ffffff;
}
footer .address a {
  text-decoration: none;
  color: #ffffff;
}
footer .contacts {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
footer .contacts .phone {
  text-decoration: none;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  cursor: pointer;
}
footer .contacts .email {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 0.6px;
}
footer .description {
  max-width: 250px;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
}
@media screen and (max-width: 860px) {
  footer .address-contacts {
    flex-direction: column;
    align-items: flex-end;
  }
  footer .address-contacts .address {
    order: 2;
    width: 70%;
    text-align: right;
    padding: 0;
    padding-left: 10px;
  }
  footer .address-contacts .contacts {
    order: 1;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  footer .wrapper-container > .flex-row {
    flex-direction: column;
  }
  footer .flex-column {
    align-items: center;
  }
  footer .w33, footer .contacts, footer .address {
    width: 100% !important;
  }
  footer .description {
    width: 100%;
    max-width: none;
  }
  footer .address-contacts .address {
    padding: 0;
    text-align: center;
  }
  footer .address-contacts .contacts {
    align-items: center;
    margin: 20px 0;
    padding: 0;
  }
  footer .address-contacts .contacts .phone + .email {
    margin-top: 10px;
  }
}

.breadcrumbs {
  padding: 30px 0 10px 0;
}
.breadcrumbs ul {
  display: flex;
}
.breadcrumbs ul * {
  font-weight: 300;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0px;
}
.breadcrumbs ul li {
  list-style: none;
}
.breadcrumbs ul li:after {
  margin: 0 7px 0 5px;
  content: "/";
}
.breadcrumbs ul li:last-child:after {
  content: "";
  margin: 0;
}
.breadcrumbs ul li a {
  text-decoration: none;
}
.breadcrumbs ul li .link {
  cursor: pointer;
  color: rgb(0, 171, 240);
  font-style: normal;
}

.input {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.button {
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  background: #efefef;
  color: #082664;
  border: 1px solid #082664;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  transition: 0.2s ease;
  position: relative;
}
.button:hover {
  background: #dcdcdc;
}
.button .loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.button .loader img {
  height: 100%;
}
.button.loader-active {
  color: #808080 !important;
}
.button.loader-active .loader {
  display: block;
}
.button.primary {
  background: #082664;
  color: #ffffff;
  border: 1px solid #082664;
}
.button.primary:hover {
  background: #0e327e;
}

.mx-datepicker-content .cell.active {
  border-radius: 100px;
}
.mx-datepicker-content .cell.active div {
  color: #ffffff !important;
}

html {
  min-width: 320px;
}

body {
  min-width: 320px;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #082664;
  box-sizing: border-box;
  font-size: 16px;
}

.wrapper-container {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.banner {
  background-color: #ffffff;
  background-image: url("/images/banner.png");
  background-position: center center;
  background-size: cover;
}
.banner .wrapper-container {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.banner .calculation {
  max-width: 450px;
  width: 50%;
  height: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
}
.banner .calculation .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.banner .calculation .flex-row .field:first-child {
  margin-right: 10px;
}
.banner .calculation .field {
  padding: 5px 0;
}
.banner .calculation .field .label {
  margin-bottom: 5px;
  display: block;
}
.banner .calculation .mx-datepicker {
  width: 100%;
}
.banner .calculation .mx-datepicker input {
  padding-right: 30px !important;
}
.banner .calculation .number-awb {
  display: flex;
}
.banner .calculation .number-awb .first {
  text-align: center;
  width: 40%;
  margin-right: 10px;
}
.banner .calculation .number-awb input {
  letter-spacing: 3px;
}
.banner .calculation .buttons {
  display: flex;
  justify-content: flex-end;
}
.banner .calculation input[type=number], .banner .calculation input[type=text] {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.banner .calculation .result {
  font-size: 14px;
  margin-left: 10px;
}
.banner .calculation .result .price {
  white-space: nowrap;
}
.banner .calculation .error {
  margin-top: 15px;
  color: red;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
.banner .calculation .information {
  margin-top: 15px;
  color: #999;
  font-size: 12px;
  font-style: italic;
}
@media screen and (max-width: 770px) {
  .banner .calculation {
    width: 100%;
    max-width: none;
  }
}
@media screen and (max-width: 480px) {
  .banner .w50 {
    width: 100%;
  }
  .banner .dates {
    flex-direction: column;
  }
  .banner .wrapper-container {
    padding: 0;
  }
}

.fast-access {
  padding: 20px 0;
}
.fast-access .wrapper-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fast-access .item {
  width: calc(50% - 20px);
  margin: 10px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
  overflow: hidden;
}
.fast-access .item .title {
  padding: 15px 20px;
  font-weight: 500;
  text-align: center;
  font-size: 22px;
  background: #082664;
  color: #ffffff;
}
.fast-access .item ul.items li {
  list-style: none;
}
.fast-access .item ul.items li:last-child a {
  border-bottom: none;
}
.fast-access .item ul.items a {
  text-decoration: none;
  padding: 15px 20px;
  display: block;
  border-bottom: 1px solid #e3e3e3;
}
.fast-access .item ul.items a:hover {
  background: #0ba9dc;
  color: #fff;
}
@media screen and (max-width: 700px) {
  .fast-access .item {
    width: 100%;
    margin: 10px 0;
  }
}

.tracking {
  padding: 20px;
}
.tracking div.field {
  display: flex;
}
.tracking div.field input {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 20px;
}
.tracking div.field input.first {
  width: 30%;
  margin-right: 10px;
  text-align: center;
}
.tracking div.buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.tracking div.buttons button {
  margin-right: 20px;
}
.tracking div.buttons .helper {
  color: #999;
  font-size: 12px;
  font-style: italic;
}

.company-description .wrapper-container {
  padding: 20px;
}
.company-description h1 {
  font-size: 22px;
  margin-bottom: 20px;
}
.company-description p {
  margin-bottom: 10px;
  line-height: 1.85;
}

.text-content__wrapper {
  padding: 30px 0;
  min-height: 70vh;
}
.text-content p {
  line-height: 1.45;
  margin-bottom: 1em;
  font-weight: 300;
}
.text-content ol {
  margin: 0 0 1.5em;
  line-height: 32px;
  list-style: none;
  padding-left: 0;
  counter-reset: li;
  margin-left: 2em;
}
.text-content ol li {
  position: relative;
  line-height: 1.45;
  margin-bottom: 1em;
  font-weight: 300;
}
.text-content ol li:before {
  content: counter(li, decimal-leading-zero) ".";
  counter-increment: li;
  padding-right: 0.5em;
  position: absolute;
  top: 0;
  left: -2em;
}
.text-content ul {
  margin: 0 0 1.5em;
  line-height: 32px;
  padding-left: 0;
  list-style: none;
}
.text-content ul li {
  position: relative;
  line-height: 1.45;
  margin-bottom: 1em;
  margin-left: 1em;
  font-weight: 300;
}
.text-content ul li:before {
  font-family: "Material Icons";
  content: "\e3fa";
  position: absolute;
  top: 0.8em;
  left: -2em;
  font-size: 0.5em;
}
.text-content p + h2 {
  margin-top: 15px;
}
.text-content p + h3 {
  margin-top: 15px;
}
.text-content h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.1;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0;
  text-align: inherit;
  margin-bottom: 30px;
}
.text-content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.1;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0;
  text-align: inherit;
  margin-bottom: 15px;
}
.text-content h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  font-family: Montserrat, sans-serif;
  letter-spacing: 0;
  text-align: inherit;
  margin-bottom: 15px;
}
.text-content table {
  margin: 0 0 1.5em;
  width: 100%;
  border: 1px solid #e4e4e5;
  border-collapse: collapse;
  border-spacing: 0;
}
.text-content table td {
  padding: 5px;
  vertical-align: top;
  border: 1px solid #e4e4e5;
  font-weight: 300;
}
.text-content img {
  height: auto;
  max-width: 100%;
}
.text-content .offers-item {
  display: flex;
}
.text-content .offersimg {
  width: 15%;
  flex-shrink: 0;
}
.text-content input[type=text] {
  border: 1px solid #e4e4e5;
  padding: 5px;
  font-size: 16px;
  outline: none;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .text-content h1 {
    font-size: 30px;
  }
  .text-content h2 {
    font-size: 22px;
  }
}

div.calculation-page .calculation {
  border-radius: 10px;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.08);
  margin: 20px auto;
  max-width: 700px;
  width: 50%;
  height: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
}
div.calculation-page .calculation .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
div.calculation-page .calculation .flex-row .field:first-child {
  margin-right: 10px;
}
div.calculation-page .calculation .field {
  padding: 5px 0;
}
div.calculation-page .calculation .field .label {
  margin-bottom: 5px;
  display: block;
}
div.calculation-page .calculation .mx-datepicker {
  width: 100%;
}
div.calculation-page .calculation .mx-datepicker input {
  padding-right: 30px !important;
}
div.calculation-page .calculation .number-awb {
  display: flex;
}
div.calculation-page .calculation .number-awb .first {
  text-align: center;
  width: 40%;
  margin-right: 10px;
}
div.calculation-page .calculation .number-awb input {
  letter-spacing: 3px;
}
div.calculation-page .calculation .buttons {
  display: flex;
  justify-content: flex-end;
}
div.calculation-page .calculation input[type=number], div.calculation-page .calculation input[type=text] {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
div.calculation-page .calculation .result {
  font-size: 14px;
  margin-left: 10px;
}
div.calculation-page .calculation .result .price {
  white-space: nowrap;
}
div.calculation-page .calculation .error {
  margin-top: 15px;
  color: red;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
div.calculation-page .calculation .information {
  margin-top: 15px;
  color: #999;
  font-size: 12px;
  font-style: italic;
}
@media screen and (max-width: 770px) {
  div.calculation-page .calculation {
    width: 100%;
    max-width: none;
  }
}
@media screen and (max-width: 480px) {
  div.calculation-page .w50 {
    width: 100%;
  }
  div.calculation-page .dates {
    flex-direction: column;
  }
  div.calculation-page .wrapper-container {
    padding: 0;
  }
}

input[type=number], input[type=text] {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

textarea {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.request-cargo-issue {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}
.request-cargo-issue .number-awb {
  text-align: center;
  letter-spacing: 3px;
}
.request-cargo-issue .field {
  padding: 10px 0;
}
.request-cargo-issue .field .label {
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.request-cargo-issue .w50 .field {
  margin-right: 10px;
}
.request-cargo-issue .w33 .field {
  margin-right: 10px;
}
.request-cargo-issue .error {
  margin-top: 15px;
  color: red;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
.request-cargo-issue .information {
  margin-top: 15px;
  color: #999;
  font-size: 12px;
  font-style: italic;
}
.request-cargo-issue .mx-datepicker {
  width: 100%;
}
.request-cargo-issue .mx-datepicker input {
  padding-right: 30px !important;
}
.request-cargo-issue .result {
  font-size: 14px;
  margin-left: 10px;
}
@media screen and (max-width: 680px) {
  .request-cargo-issue .w50 {
    width: 100%;
  }
  .request-cargo-issue .flex-row {
    flex-direction: column;
  }
  .request-cargo-issue .w50 .field {
    margin-right: 0px;
  }
}

.form-generator {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.08);
  background: #ffffff;
}
.form-generator .number-awb {
  text-align: center;
  letter-spacing: 3px;
}
.form-generator h3 {
  padding: 0 10px;
  margin-top: 30px;
}
.form-generator .checkbox-labels {
  display: flex;
  margin: 10px;
}
.form-generator .checkbox-labels label {
  cursor: pointer;
  display: block;
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-generator .checkbox-labels label:first-child {
  border-radius: 4px 0 0 4px;
  border-right: none;
}
.form-generator .checkbox-labels label:last-child {
  border-radius: 0 4px 4px 0;
}
.form-generator .field {
  padding: 10px;
}
.form-generator .field .label {
  margin-bottom: 5px;
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.form-generator .w50 .field {
  margin-right: 10px;
}
.form-generator .w33 .field {
  margin-right: 10px;
}
.form-generator .buttons {
  padding: 0 10px;
}
.form-generator .error {
  margin-top: 15px;
  color: red;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
.form-generator .information {
  margin-top: 15px;
  color: #999;
  font-size: 12px;
  font-style: italic;
}
.form-generator .mx-datepicker {
  width: 100%;
}
.form-generator .mx-datepicker input {
  padding-right: 30px !important;
}
.form-generator .result {
  font-size: 14px;
  margin-left: 10px;
}
@media screen and (max-width: 680px) {
  .form-generator .w33 {
    width: 100%;
  }
  .form-generator .w50 {
    width: 100%;
  }
  .form-generator .flex-row {
    flex-direction: column;
  }
  .form-generator .w50 .field {
    margin-right: 0px;
  }
}

.large-text * {
  font-size: 20px;
}
.large-text .text-content h2 {
  font-size: 30px;
}
.large-text .text-content h3 {
  font-size: 24px;
}
.large-text .form-generator .field .label {
  font-size: 16px;
}
.large-text .form-generator .information {
  font-size: 16px;
}
.large-text .breadcrumbs ul * {
  font-size: 18px;
}
.large-text .head-menu ul.menu li > a, .large-text .head-menu ul.menu li > span.link {
  font-size: 22px;
}
.large-text .tracking div.buttons .helper {
  font-size: 16px;
}
.large-text .banner .calculation .information {
  font-size: 16px;
}
.large-text .banner .calculation .title {
  font-size: 24px;
}

.small-text * {
  font-size: 12px;
}
.small-text .text-content h1 {
  font-size: 36px;
}
.small-text .text-content h2 {
  font-size: 22px;
}
.small-text .text-content h3 {
  font-size: 16px;
}
.small-text .form-generator .field .label {
  font-size: 8px;
}
.small-text .form-generator .information {
  font-size: 8px;
}
.small-text .breadcrumbs ul * {
  font-size: 10px;
}
.small-text .head-menu ul.menu li > a, .small-text .head-menu ul.menu li > span.link {
  font-size: 14px;
}
.small-text .tracking div.buttons .helper {
  font-size: 8px;
}
.small-text .banner .calculation .information {
  font-size: 8px;
}
.small-text .banner .calculation .title {
  font-size: 16px;
}