header {
    padding: 10px 0;
    div.wrapper-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    div.visible {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        font-style: normal;
        font-weight: 500;

        .item {
            padding: 5px;
            cursor: pointer;
        }

        .big { font-size: 26px; }
        .main { font-size: 22px; }
        .small { font-size: 18px; }
    }
    div.contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .phone {
            text-decoration: none;
            color: $main-color;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: right;
            //letter-spacing: -0.01em;
            cursor: pointer;
        }
        .email {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            text-decoration: none;
            color: $main-color;
            letter-spacing: 0.6px;
        }
    }
    div.lang {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;

        .item {
            display: block;
            padding: 10px;
            position: relative;
            cursor: pointer;
            text-decoration: none;
            color: $main-color;

            &:after {
                content: '';
                background: $main-color;
                width: 1px;
                height: 20px;
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            &:last-child:after {
                display: none;
            }
        }
    }
    div.description {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        //letter-spacing: -0.05em;
        text-transform: uppercase;
        max-width: 350px;
    }
    a.logo {
        display: block;
        width: 200px;
        img {
            width: 100%;
            display: block;
        }
    }

    @media screen and (max-width: 800px) {
        div.wrapper-container {
            flex-wrap: wrap;

            .logo, .description {
                width: 50%;
            }
            .description {
                text-align: right;
                padding-left: 10px;
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }

    @media screen and (max-width: 600px) {
        div.contacts { display: none; }
    }

    @media screen and (max-width: 500px) {
        div.wrapper-container {
            .logo {
                width: 100%;
                margin-bottom: 10px;
                img {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
            .description {
                display: none;
            }
        }
    }
    @media screen and (max-width: 400px) {
        div.wrapper-container {

        }
    }
}

.head-menu {
    background: $main-color;
    color: #ffffff;

    ul.menu {
        display: flex;
        flex-direction: row;
        align-items: center;

        li {
            list-style: none;
            position: relative;

            & > a, & > span.link {
                cursor: pointer;
                white-space: nowrap;
                text-decoration: none;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                //letter-spacing: -0.05em;
                //text-transform: uppercase;
                padding: 14px 15px;
                display: block;

                &:hover {

                    /*&:after {
                        content: '';
                        height: 3px;
                        background: #ffffff;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }*/
                }
            }
            &:hover {
                a, span.link {
                    background: #183573;
                    &:after {
                        content: '';
                        height: 3px;
                        background: #ffffff;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                }

                .submenu { display: block; }
            }

            & .submenu {
                z-index: 10;
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                min-width: 200px;
                background: #ffffff;
                -webkit-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);

                ul {
                    flex-direction: column;
                    align-items: stretch;
                    flex-shrink: 0;

                    li {
                        border-bottom: 1px solid $blue-color;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                a {
                    padding: 15px 20px;
                    background: $main-color;
                    color: #ffffff;
                    display: block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    &:after {
                        display: none;
                    }

                    &:hover {
                        background: $blue-color;
                        color: #ffffff;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .mobile {
        display: none;

        div.contacts {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .phone {
                text-decoration: none;
                color: #ffffff;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                text-align: right;
                //letter-spacing: -0.01em;
                cursor: pointer;
            }
            .email {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                cursor: pointer;
                text-decoration: none;
                color: #ffffff;
                letter-spacing: 0.6px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .wrapper-container {

        }
        .mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menu-button {
                display: block;
                padding: 10px;
                svg {
                    transform: scale(0.7);
                    fill: #ffffff;
                }
                &:active {
                    background: #183573;
                }
            }
        }

        ul.menu {
            display: none;
            position: absolute;
            top:0;
            left: 0;
            bottom: 0;
            background: #ffffff;
            z-index: 10;
            width: 300px;
            -webkit-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 18px -8px rgba(34, 60, 80, 0.2);

            & > li {
                a, span.link {
                    color: $main-color;
                }
                &:hover {
                    > a, > span.link {
                        color: #ffffff;
                        background: #183573;
                        &:after {
                            display: none;
                        }
                    }
                    .submenu { display: block; }
                }
                & .submenu {
                    background: #ffffff;
                    position: static;
                    width: auto;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;

                    ul {
                        li {
                            a {
                                padding-left: 30px;
                            }
                        }
                    }
                }
            }

        }
    }
}

footer {
    background: $main-color;
    color: #ffffff;

    .wrapper-container {
        padding: 20px;
    }

    .logo {
        display: block;
        text-decoration: none;
        width: 100%;
        max-width: 250px;
        img {
            width: 100%;
            display: block;
        }
    }

    .address-contacts {

    }

    .address {
        padding: 0 10px;
        color: #ffffff;
        a {
            text-decoration: none;
            color: #ffffff;
        }
    }

    .contacts {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .phone {
            text-decoration: none;
            color: #ffffff;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: right;
            //letter-spacing: -0.01em;
            cursor: pointer;
        }
        .email {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            text-decoration: none;
            color: #ffffff;
            letter-spacing: 0.6px;
        }
    }

    .description {
        max-width: 250px;
        color: #ffffff;
        margin-top: 10px;
        text-align: center;
    }

    @media screen and (max-width: 860px) {
        .address-contacts {
            flex-direction: column;
            align-items: flex-end;

            .address {
                order: 2;
                width: 70%;
                text-align: right;
                padding: 0;
                padding-left: 10px;
            }
            .contacts {
                order: 1;
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }

    @media screen and (max-width: 670px) {
        .wrapper-container > .flex-row {
            flex-direction: column;
        }
        .flex-column {
            align-items: center;
        }

        .w33, .contacts, .address { width: 100% !important; }
        .description {
            width: 100%;
            max-width: none;
        }

        .address-contacts {
            .address {
                padding: 0;
                text-align: center;
            }
            .contacts {
                align-items: center;
                margin: 20px 0;
                padding: 0;

                .phone + .email {
                    margin-top: 10px;
                }
            }
        }

    }
}

.breadcrumbs {
    padding: 30px 0 10px 0;

    ul {
        display: flex;

        * {
            font-weight: 300;
            font-size: 14px;
            font-family: Roboto, sans-serif;
            letter-spacing: 0px;
        }

        li {
            list-style: none;

            &:after {
                margin: 0 7px 0 5px;
                content: "/";
            }

            &:last-child:after {
                content: "";
                margin: 0;
            }

            a {
                text-decoration: none;
            }

            .link {
                cursor: pointer;
                color: rgb(0, 171, 240);
                font-style: normal;
            }
        }
    }
}
