.w20 { width: 20%; }
.w25 { width: 25%; }
.w30 { width: 30%; }
.w33 { width: 33.33333%; }
.w40 { width: 40%; }
.w50 { width: 50%; }
.w60 { width: 60%; }
.w70 { width: 70%; }
.w80 { width: 80%; }
.w100 { width: 100%; }

.p5 { padding: 5px; }
.p10 { padding: 10px; }
.p15 { padding: 15px; }
.p20 { padding: 20px; }

.m5 { margin: 5px; }
.m10 { margin: 10px; }
.m15 { margin: 15px; }
.m20 { margin: 20px; }

.mt15 { margin-top: 15px; }
.mb15 { margin-bottom: 15px; }

.flex-wrap {
    flex-wrap: wrap;
}
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center { align-items: center; }
.align-end { align-items: flex-end; }
