.input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    padding: 6px 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}

.button {
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    background: #efefef;
    color: $main-color;
    border: 1px solid $main-color;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    transition: 0.2s ease;
    position: relative;

    &:hover {
        background: #dcdcdc;
    }

    .loader {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img {
            height: 100%;
        }
    }

    &.loader-active {
        color: #808080!important;
        & .loader { display: block; }
    }

    &.primary {
        background: $main-color;
        color: #ffffff;
        border: 1px solid $main-color;

        &:hover {
            background: #0e327e;
        }
    }
}

.mx-datepicker-content {
    .cell.active {
        border-radius: 100px;
        div {
            color: #ffffff!important;
        }
    }
}
